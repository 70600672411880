import React, { useEffect, useState } from "react";
import {
  Text,
  View,
  Pressable,
  SafeAreaView,
  Image,
  Share,
  Linking,
  Platform,
  Alert,
} from "react-native";
import { ref, getStorage, getDownloadURL } from "firebase/storage";
import { LinearGradient } from "expo-linear-gradient";
import FirebaseApp from "./firebase";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import styles from "./styles";
import ShareModal from "./ShareModal";

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function App() {
  // setting up the query
  const query = useQuery();
  const [state, setState] = useState({
    termsAndConditionsAccepted: false,
    shared: false,
    termsAndConditionsDeclined: false,
    liked: false,
  });
  const [imgURL, setImgURL] = useState(null);
  const [modal, setModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const storage = getStorage(FirebaseApp);
  const imgId = query.get("imgId");
  const redirect = query.get("redirect");

  const message = `Thanks Golden Circle for my free Golden Pash popper! #SmilePay #GoldenCircle https://smile2pay.com.au/?redirect=true&imgId=${imgId}`

  useEffect(() => {
    if (redirect === "true") {
      setState({
        termsAndConditionsDeclined: false,
        termsAndConditionsAccepted: true,
        shared: true,
        liked: true,
      });
    }
    if (imgId) {
      getDownloadURL(ref(storage, `/images/capture/${imgId}.png`)).then((url) => {
        setImgURL(url);
      });
    } else {
      Alert.alert("Image Not Found! Please Try Again");
    }
  }, [imgId, storage]);

  const likeFB = async () => {
    try {
      if (Platform.OS === "android") {
        await Linking.openURL("fb://page/881021291988481");
      } else if (Platform.OS === "ios") {
        await Linking.openURL("fb://profile/?id=881021291988481");
      } else {
        await Linking.openURL("https://www.facebook.com/GoldenCircle");
      }
    } catch (err) {
      await Linking.openURL("https://www.facebook.com/GoldenCircle");
    }
  };

  return (
    <View>
      <LinearGradient
        colors={["#d65b09", "#ebee3e"]}
        style={styles.background}
      />
      <SafeAreaView>
        <View style={styles.container}>
          <TermsAndConditionsModal
            setModal={setModal}
            visible={modal}
            state={state}
            setState={setState}
            imgId = {imgId}
          />
          <ShareModal
            visible={shareModal}
            setModal={setShareModal}
            state={setState}
            state={state}
            message={message}
            url = {`https://smile2pay.com.au/?redirect=true&imgId=${imgId}`}
          />

          <View style={styles.logoContainer}>
            <Image
              style={styles.logo}
              source={require("./assets/Golden_Circle_Logo.png")}
            />
            <Text style={styles.logoSpan}>X</Text>
            <Image
              style={styles.logo}
              source={require("./assets/Smile_Pay_Logo.png")}
            />
          </View>
          <View style={styles.imageContainer}>
            {!state.termsAndConditionsDeclined ? (
              state.shared && state.termsAndConditionsAccepted ? (
                <Text style={[styles.infoText, { color: "green" }]}>
                  Cheers! You have entered the draw!
                </Text>
              ) : (
                <Text style={styles.infoText}>
                  Share your smile on Facebook to enter the draw to win a year's
                  supply of golden Circle Juice
                </Text>
              )
            ) : (
              <Text style={[styles.infoText, { color: "red" }]}>
                To Enter The Prize Draw You Must Accept the T&amp;C's
              </Text>
            )}

            <Image
              style={styles.mainImage}
              source={{
                uri: imgURL,
              }}
            />
          </View>
          <View style={styles.buttonContainer}>
            <Pressable
              style={
                !state.termsAndConditionsAccepted
                  ? styles.button
                  : [styles.button, styles.buttonActive]
              }
              onPress={() => {
                if (redirect === "true") {
                  Linking.openURL(
                    "http://www.goldencircle.com.au/terms-conditions"
                  );
                } else {
                  setModal(true);
                }
              }}
            >
              <Text
                style={
                  !state.termsAndConditionsAccepted
                    ? styles.buttonTextDisabled
                    : styles.buttonTextActive
                }
              >
                {redirect === "true" ? "SEE T&C'S" : "ACCEPT T&C'S"}
              </Text>
            </Pressable>
            <Pressable
              style={
                !state.shared
                  ? styles.button
                  : [styles.button, styles.buttonActive]
              }
              onPress={() => {
                if (!state.termsAndConditionsAccepted) {
                  return setState({
                    ...state,
                    termsAndConditionsDeclined: true,
                  });
                }
                setShareModal(true);
              }}
            >
              <Text
                style={
                  !state.shared
                    ? styles.buttonTextDisabled
                    : styles.buttonTextActive
                }
              >
                SHARE YOUR SMILE
              </Text>
            </Pressable>
            <Pressable
              style={
                !state.liked
                  ? styles.button
                  : [styles.button, styles.buttonActive]
              }
              onPress={async () => {
                await likeFB();
                setState({ ...state, liked: true });
              }}
            >
              <Text
                style={
                  !state.liked
                    ? styles.buttonTextDisabled
                    : styles.buttonTextActive
                }
              >
                LIKE TO FOLLOW
              </Text>
            </Pressable>
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
}
