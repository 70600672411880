import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDqnMKo30HAgwrJhbkKeL3KDSZEYxS6Af0",
  authDomain: "imagerecognition-6b892.firebaseapp.com",
  projectId: "imagerecognition-6b892",
  storageBucket: "imagerecognition-6b892.appspot.com",
  messagingSenderId: "564913531908",
  appId: "1:564913531908:web:baee7b849336c8e13d5951",
  measurementId: "G-LKMK4C9XCF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;