import React, {useEffect} from "react";
import {
  Modal,
  View,
  Text,
  ScrollView,
  StyleSheet,
  Pressable,
  Image,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import { getDatabase, ref, set, child, get, remove } from 'firebase/database'
import app from './firebase'

const { height, width } = Dimensions.get("window");

const TermsAndConditionsModal = ({ setModal, visible, setState, state, imgId }) => {
  const database = getDatabase(app)

  const updateRecords = async (state) => {
    const snapshot = await get(child(ref(database), `records/${imgId}`))
    const time = new Date()
    if (snapshot.exists()) {
      remove(ref(database, `records/${imgId}`))
      set(ref(database, `records/${imgId}`), [...snapshot.val(), {accepted: state, timestamp: time.toISOString()}])
    } else(set(ref(database, `records/${imgId}`), [{accepted: state, timestamp: time.toISOString()}]))
  }

  return (
    <View style={styles.centeredView}>
      <Modal
        visible={visible}
        animationType={"slide"}
        onRequestClose={() => setModal(false)}
        transparent={true}
      >
        <View style={styles.modalView}>
          <View style={styles.headerContainer}>
            <Text style={styles.headerText}>Terms &amp; Conditions</Text>
          </View>
          <ScrollView style={styles.modalText}>
            <View>
              <TouchableOpacity>
                <Text style={styles.termsText}>
                  1. Eligible entrants: Entry is only open to QLD residents who
                  are 16 years and over. Entrants under the age of 18 must have
                  parent or legal guardian approval to enter.
                </Text>
                <Text style={styles.termsText}>
                  2. To enter the Promotion, the entrant must complete following
                  steps during the Promotional Period:
                  <Text style={styles.nestedText}>
                    a) visit any SmilePay vending machine within QLD which
                    displays advertising for this Promotion;
                  </Text>
                  <Text style={styles.nestedText}>
                    b) have their photo taken by the SmilePay vending machine by
                    pressing start (“Photo”). Once the Photo has been taken, a
                    QR code will be provided on screen;{" "}
                  </Text>
                  <Text style={styles.nestedText}>
                    c) scan the QR code and be directed to share the Photo to
                    their Facebook account and post the Photo with the hashtags
                    #SmilePay and #GoldenCircle; and{" "}
                  </Text>
                  <Text style={styles.nestedText}>
                    d) (optional) like the Golden Circle Facebook Page (located
                    at: www.facebook.com/GoldenCircle).{" "}
                  </Text>
                  Entrants who complete the above instructions will also receive
                  a Golden Circle popper via the SmilePay vending machine.
                </Text>
                <Text style={styles.termsText}>
                  3. Entries permitted: Entrants may enter multiple times
                  provided each entry is submitted separately in accordance with
                  the entry instructions above. Each entry submitted by an
                  entrant must contain a different Photo. By completing the
                  entry method, the entrant will receive one (1) entry.
                </Text>
                <Text style={styles.termsText}>
                  4. Draw: computerised random selection - 10/01/22 at 12:00 pm
                  AEDT
                  <Text style={styles.nestedText}>
                    The prize is a year's supply of a variety of Golden Circle
                    beverages
                  </Text>
                  <Text style={styles.nestedText}>
                    The flavor inclusions will be determined by the Promoter.{" "}
                  </Text>
                  <Text style={styles.nestedText}>
                    Prize equates to 54 x bottles in total (delivered in two
                    batches)
                  </Text>
                </Text>
                <Text style={styles.termsText}>
                  5. The winner will be contacted via their Facebook account
                  within fourteen (14) days of the draw.
                </Text>
                <Text style={styles.termsText}>
                  6. Prize must be claimed by 11/02/22 at 12:00 pm AEDT. In the
                  event of an unclaimed prize, the prize will be redrawn on
                  14/02/22 at 12:00 pm AEDT at Golden Circle, Floor 27, 2
                  Southbank Boulevard, Southbank VIC 3006, Australia. The winner
                  of the redraw will be notified via their Facebook account
                  within fourteen (14) days of the redraw.
                </Text>
                <Text style={styles.termsText}>
                  7. The entrant agrees and acknowledges that they have read
                  these Conditions of Entry (and Schedule) and that entry into
                  the Promotion is deemed to be acceptance of these Conditions
                  of Entry (and Schedule). Any capitalised terms used in these
                  Conditions of Entry have the meaning given in the Schedule,
                  unless stated otherwise.
                </Text>
                <Text style={styles.termsText}>
                  8. The Promotion commences on the Start Date and ends on the
                  End Date ("Promotional Period"). Entries are deemed to be
                  received at the time of receipt by the Promoter and not at the
                  time of transmission or deposit by the entrant. Records of the
                  Promoter and its agencies are final and conclusive as to the
                  time of receipt.
                </Text>
                <Text style={styles.termsText}>
                  9. Valid and eligible entries will be accepted during the
                  Promotional Period.
                </Text>
                <Text style={styles.termsText}>
                  10. Employees (and their immediate family members) of
                  agencies/companies directly associated with the conduct of
                  this Promotion, the Promoter, businesses involved in
                  determination of winner/s for the Promotion, businesses
                  involved in the management of the Promotion, any organisation
                  benefiting from the Promotion, the Promoter’s distributors,
                  suppliers, subsidiary companies/businesses and associated
                  companies and agencies are not eligible to enter. "Immediate
                  family member" means any of the following: spouse, ex-spouse,
                  de-facto spouse, child or step-child (whether natural or by
                  adoption), parent, step-parent, grandparent, step-grandparent,
                  uncle, aunt, niece, nephew, brother, sister, step-brother,
                  step-sister or 1st cousin.
                </Text>
                <Text style={styles.termsText}>
                  11. If a prize is won by a person under the age of 18, the
                  prize may be awarded to the winner's parent or guardian.
                </Text>
                <Text style={styles.termsText}>
                  12. Draw:
                  <Text style={styles.nestedText}>
                    a) The draw will take place at Golden Circle, Floor 27, 2
                    Southbank Boulevard, Southbank VIC 3006, Australia at 12:00
                    pm AEDT on 10/01/22 using computerised random selection. i)
                    The first valid entry drawn will be the winner of the prize
                    specified in the Schedule above.
                  </Text>
                  <Text style={styles.nestedText}>
                    b) The draw conductor may draw reserve winners in case of
                    ineligible or invalid entries.
                  </Text>
                  <Text style={styles.nestedText}>
                    c) If a draw is scheduled on the weekend or a public
                    holiday, the draw will be conducted at the same time and
                    location on the following business day. The Promoter will
                    ensure each draw is open for public scrutiny and anyone may
                    witness the draw on request. The winner of a drawn prize is
                    determined by chance.
                  </Text>
                </Text>
                <Text style={styles.termsText}>
                  13. All reasonable attempts will be made to contact the
                  winner.
                </Text>
                <Text style={styles.termsText}>
                  14. If the winner chooses not to take their prize (or is
                  unable to), or does not take or claim a prize by the time
                  specified by the Promoter, or is unavailable, they forfeit the
                  prize and the Promoter is not obliged to substitute the prize.
                </Text>
                <Text style={styles.termsText}>
                  15. Entry and continued participation in the Promotion is
                  dependent on the entrant following and acting in accordance
                  with Facebook Statement of Rights and Responsibilities
                  (http://www.facebook.com/terms.php). This Promotion adheres to
                  the terms and conditions set out in the Facebook promotion
                  guidelines which can be found at:
                  http://www.facebook.com/promotions_guidelines.php. Any
                  questions or comments regarding the Promotion must be directed
                  to the Promoter, not to Facebook. The entrant releases
                  Facebook and its associated companies from all liabilities
                  arising in respect of the Promotion. Entrants acknowledge that
                  the Promotion is in no way sponsored, endorsed or administered
                  by, or associated with Facebook.
                </Text>
                <Text style={styles.termsText}>
                  16. The value of the prize is accurate and based upon the
                  recommended retail value of the prize (inclusive of GST) at
                  the date of printing. The Promoter accepts no responsibility
                  for any variation in the value of the prize after that date.{" "}
                </Text>
                <Text style={styles.termsText}>
                  17. No part of a prize is exchangeable, redeemable for cash or
                  any other prize or transferable, unless otherwise specified in
                  writing by the Promoter.
                </Text>
                <Text style={styles.termsText}>
                  18. If the prize (or portion of the prize) is unavailable the
                  Promoter reserves the right to substitute the prize (or that
                  portion of the prize) to a prize of equal or greater value and
                  specification.
                </Text>
                <Text style={styles.termsText}>
                  19. No entry fee is charged by the Promoter to enter the
                  Promotion. Where entry is allowed online, there is no
                  additional cost to enter the Promotion other than any cost
                  paid by the entrant to access the website or social media
                  platform of entry via their Internet service provider.
                </Text>
                <Text style={styles.termsText}>
                  20. If there is a dispute as to the identity of an entrant or
                  winner, the Promoter reserves the right, in its sole
                  discretion, to determine the identity of the entrant or
                  winner.
                </Text>
                <Text style={styles.termsText}>
                  21. Entrants' personal information will be collected by the
                  Promoter. Personal information will be stored on the
                  Promoter's database. The Promoter may use this information for
                  future marketing purposes regarding its products, including
                  contacting the entrant electronically. The Promoter is bound
                  by the Australian Privacy Principles in accordance with the
                  Privacy Act 1988 (Cth) and its privacy policy which is located
                  at https://www.goldencircle.com.au/privacy. The Promoter's
                  privacy policy contains information about how the entrant may
                  access, update and seek correction of the personal information
                  the Promoter holds about them and how the entrant may complain
                  about any potential breach by the Promoter of the Australian
                  Privacy Principles or any other Australian privacy laws and
                  how such complaints will be dealt with. The Promoter collects
                  personal information about entrants to enable them to
                  participate in this Promotion and may disclose the entrants'
                  personal information to third parties including its
                  contractors and agents, prize suppliers and service providers
                  to assist in conducting this Promotion. If the entrant does
                  not provide their personal information as requested, they may
                  be ineligible to enter or claim a prize in the Promotion.
                  Personal information collected from entrants will not be
                  disclosed to any entity located outside of Australia.
                </Text>
                <Text style={styles.termsText}>
                  22. If a prize is provided to the Promoter by a third party,
                  the prize is subject to the terms and conditions of the third
                  party prize supplier and the provision of the prize is the
                  sole responsibility of the third party and not the Promoter.
                  The terms and conditions which apply to the prize at the time
                  it is issued to the winner will prevail over these Conditions
                  of Entry, to the extent of any inconsistency. The Promoter
                  accepts no responsibility or liability for any delay or
                  failure by the third party to deliver the prize, any delay or
                  failure relating to the prize itself or failure by the third
                  party to meet any of its obligations in these Conditions of
                  Entry or otherwise.
                </Text>
                <Text style={styles.termsText}>
                  23. Any guarantee or warranty given is in addition to any
                  relevant statutory guarantees and warranties and nothing in
                  these Conditions of Entry restricts, excludes or modifies or
                  purports to restrict, exclude or modify any statutory consumer
                  rights under any applicable law including the Competition and
                  Consumer Act 2010 (Cth).
                </Text>
                <Text style={styles.termsText}>
                  24. If for any reason any aspect of this Promotion is not
                  capable of running as planned, including by reason of computer
                  virus, communications network failure, bugs, tampering,
                  unauthorised intervention, fraud, technical failure or any
                  cause beyond the control of the Promoter, the Promoter may in
                  its sole discretion cancel, terminate, modify or suspend the
                  Promotion and invalidate any affected entries, or suspend or
                  modify a prize, subject to State or Territory regulation.
                </Text>
                <Text style={styles.termsText}>
                  25. The Promoter reserves the right, at any time, to validate
                  and check the authenticity of entries and entrant's details
                  (including an entrant's identity, age and place of residence).
                  In the event that a winner cannot provide suitable proof as
                  required by the Promoter to validate their entry, the winner
                  will forfeit the prize in whole and no substitute will be
                  offered. Incomplete, indecipherable, inaudible, incorrect and
                  illegible entries, as applicable, will at the Promoter's
                  discretion be deemed invalid and not eligible to win. Entries
                  containing offensive or defamatory comments, or which breach
                  any law or infringe any third party rights, including
                  intellectual property rights, are not eligible to win. The use
                  of any automated entry software or any other mechanical or
                  electronic means that allows an individual to automatically
                  enter repeatedly is prohibited and may render all entries
                  submitted by that individual invalid.
                </Text>
                <Text style={styles.termsText}>
                  26. All material submitted on entry (including Photo(s)) must
                  NOT:
                  <Text style={styles.nestedText}>
                    (a) be in breach of any laws, regulations and rights, e.g.
                    any laws regarding intellectual property (copyright,
                    trademarks, etc), defamation and privacy;
                  </Text>
                  <Text style={styles.nestedText}>
                    (b) be defamatory, obscene, derogatory, pornographic,
                    sexually inappropriate, contain nudity, aggressive, violent,
                    abusive, harassing, threatening, objectionable or
                    discriminate/vilify any section of the community with
                    respect to race, ethnicity, nationality, religion, origin,
                    sexual preference, mental illness, disability or gender or
                    unsuitable for publication; or
                  </Text>
                  <Text style={styles.nestedText}>
                    (c) contain viruses. Entrants warrant that they own or have
                    the right to license the copyright in any entry submitted by
                    them into this Promotion, for the purposes of this
                    Promotion, that no rights have been granted to any third
                    party in respect of any such entry which would prevent the
                    entry being used as contemplated by this Promotion, and that
                    the use by the Promoter of any such entry will not breach
                    any laws or infringe the rights of any person (including
                    without limitation with respect to privacy, intellectual
                    property and defamation). Entrants must obtain prior consent
                    from any person or from the owner(s) of any property that
                    appears in their entry. By entering, all entrants license
                    and grant the Promoter, its affiliates and sub-licensees an
                    exclusive, royalty-free, perpetual, worldwide, irrevocable,
                    and sub-licensable right to use, reproduce, modify, adapt,
                    publish and display their entry (including any portion of
                    their entry) for any purpose, including but not limited to
                    future promotional, marketing or publicity purposes, in any
                    media, without compensation, restriction on use, attribution
                    or liability. Entrants further agree, upon request by the
                    Promoter, to assign all of their rights, title and interest
                    (including copyright) in and to their entry to the Promoter
                    and to sign any legal documentation to confirm such
                    assignment. Entrants agree that their entry is their
                    original work and does not infringe the rights of third
                    parties, or that they have obtained full prior consent from
                    any person who has jointly created or has any rights in the
                    aforementioned material. Entrants consent to any use of
                    their entry that may otherwise infringe their moral rights.
                    Entrants are responsible for all entries they submit on
                    entry. The Promoter will not be liable for any entries, to
                    the extent permitted by law. The Promoter reserves the right
                    to remove, request removal or decline to publish any entry
                    or portion of an entry for any reason whatsoever, including
                    if in breach of these Terms and Conditions. The Promoter
                    will have no liability to entrants if it exercises this
                    right and entrants must comply with any request made by the
                    Promoter pursuant to this paragraph.The entrant warrants and
                    represents that any material sent or provided by the entrant
                    to the Promoter will not infringe any copyright, trademarks
                    or other intellectual property rights of any third party
                    (including moral rights) and that the entrant has all rights
                    to use the materials and has obtained all necessary consents
                    to comply with any relevant privacy and/or confidentiality
                    requirements. Entrants agree to indemnify the Promoter for
                    any breach of the Terms and Conditions including this
                    clause.
                  </Text>
                </Text>
                <Text style={styles.termsText}>
                  27. The Promoter reserves the right to disqualify entries in
                  the event of non-compliance with these Conditions of Entry. In
                  the event that there is a dispute concerning the conduct of
                  the Promotion or claiming a prize, the Promoter will resolve
                  the dispute in direct consultation with the entrant. If the
                  dispute cannot be resolved the Promoter’s decision will be
                  final.
                </Text>
                <Text style={styles.termsText}>
                  28. The Promoter and its associated agencies and companies
                  will not be liable for any loss (including, without
                  limitation, indirect, special or consequential loss or loss of
                  profits), expense, damage, personal injury or death which is
                  suffered or sustained (whether or not arising from any
                  person's negligence or wilful misconduct) in connection with
                  this Promotion or accepting or using any prize (or
                  recommendation), except for any liability which cannot be
                  excluded by law (in which case that liability is limited to
                  the minimum allowable by law).
                </Text>
                <Text style={styles.termsText}>
                  29. The entrant will participate in and co-operate as required
                  with all reasonable marketing and editorial activities
                  relating to the Promotion, including (but not limited to)
                  being recorded, photographed, filmed or interviewed and
                  acknowledges that the Promoter may use any such marketing and
                  editorial material without further reference or compensation
                  to them.
                </Text>
                <Text style={styles.termsText}>
                  30. The Promoter accepts no responsibility for any tax
                  implications and the entrant must seek their own independent
                  financial advice in regards to the tax implications relating
                  to the prize or acceptance of the prize.
                </Text>
                <Text style={styles.termsText}>
                  31. Failure by the Promoter to enforce any of its rights at
                  any stage does not constitute a waiver of these rights.
                </Text>
              </TouchableOpacity>
            </View>
          </ScrollView>
          <View style={styles.buttonContainer}>
            <Pressable
              onPress={() => {
                updateRecords(true)
                setModal(false);
                setState({
                  ...state,
                  termsAndConditionsAccepted: true,
                  termsAndConditionsDeclined: false,
                });
              }}
            >
              <Image
                style={[styles.optionButtons, styles.tickButton]}
                source={require("./assets/red-tick.png")}
              />
            </Pressable>
            <Pressable
              onPress={() => {
                updateRecords(false)
                setModal(false);
                setState({
                  ...state,
                  termsAndConditionsDeclined: true,
                  termsAndConditionsAccepted: false,
                });
              }}
            >
              <Image
                style={[styles.optionButtons, styles.crossButton]}
                source={require("./assets/red-cross.png")}
              />
            </Pressable>
          </View>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    position: "relative",
    alignSelf: "center",
    alignItems: "center",
    marginVertical: 50,
    marginHorizontal: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    height: 0.9 * height,
  },
  headerContainer: {
    marginVertical: 20,
  },
  headerText: {
    fontSize: 0.028 * height,
    fontWeight: "900",
    textTransform: "uppercase",
    color: "red",
  },
  modalText: {
    marginBottom: 60,
    textAlign: "center",
  },
  termsText: {
    marginVertical: 5,
    fontSize: 0.018 * height,
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "black",
  },
  buttonContainer: {
    flexDirection: "row",
    marginBottom: 30,
  },
  optionButtons: { resizeMode: "contain", marginHorizontal: 20 },
  crossButton: {
    height: 0.08 * height,
    width: 0.08 * height,
  },
  tickButton: {
    height: 0.09 * height,
    width: 0.09 * height,
  },
});

export default TermsAndConditionsModal;
