import React, { useState } from "react";
import {
  Modal,
  View,
  Text,
  StyleSheet,
  Pressable,
  TouchableOpacity,
  Dimensions,
  Share,
} from "react-native";
import ClipBoard from "@react-native-clipboard/clipboard";

const { height, width } = Dimensions.get("window");

const ShareModal = ({ setModal, visible, state, setState, message, url }) => {
  const [modalState, setModalState] = useState({ copied: false, tried: false });
  const [text, setText] = useState("Click Text to Copy");

  const copyToClipboard = () => {
    ClipBoard.setString(
      message
    );
  };

  const shareImage = async () => {
    const sharedAction = await Share.share({
      url,
    });
    if (sharedAction) {
      setState({ ...state, shared: true });
    }
  };

  return (
    <View style={styles.centeredView}>
      <Modal
        visible={visible}
        animationType={"slide"}
        onRequestClose={() => setModal(false)}
        transparent={true}
      >
        <View style={styles.modalView}>
          <View style={styles.copyContainer}>
            <TouchableOpacity
              onPress={() => {
                copyToClipboard();
                setText("Copied");
                setModalState({ tries: false, copied: true });
              }}
            >
              <Text selectable={true} style={styles.copyText}>
                Thanks Golden Circle for my free Golden Pash popper! #SmilePay
                #GoldenCircle
              </Text>
            </TouchableOpacity>
          </View>
          <Text
            style={
              state.tried
                ? [styles.copyPrompt, { color: "yellow" }]
                : styles.copyPrompt
            }
          >
            {text}
          </Text>
          <Pressable
            onPress={async () => {
              if (modalState.copied) {
                await shareImage();
                setModal(false);
              } else {
                setText("Copy Text to Proceed");
                setModalState({ ...state, tried: true });
              }
            }}
            style={styles.button}
          >
            <Text style={styles.buttonText}>
              Go To Facebook
              <Text style={[styles.buttonText, { color: "yellow" }]}>
                (Copy Text First)
              </Text>
            </Text>
          </Pressable>
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    position: "relative",
    alignSelf: "center",
    alignItems: "center",
    marginVertical: 0.3 * height,
    marginHorizontal: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    height: 0.4 * height,
  },
  button: {
    borderRadius: 25,
    margin: 7,
    paddingVertical: 0.013 * height,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "red",
    borderWidth: 2,
    backgroundColor: "red",
  },
  buttonText: {
    fontWeight: "900",
    fontSize: 0.02 * height,
    color: "white",
  },

  copyContainer: {
    borderWidth: 2,
    borderColor: "red",
    marginTop: 20,
  },

  copyText: {
    fontSize: 0.02 * height,
    textAlign: "center",
    color: "red",
    fontWeight: "normal",
    margin: 20,
  },

  copyPrompt: {
    fontSize: 0.018 * height,
    color: "black",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
});

export default ShareModal;
