import { StyleSheet } from "react-native";
import { Dimensions } from "react-native";

const { height, width } = Dimensions.get("screen");

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  background: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    minHeight: height,
    height: "100%",
  },
  logoContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "70%",
    marginTop: 25,
    height: 0.08 * height,
    flex: 0.7,
  },
  logo: { resizeMode: "contain", height: 0.08 * height, width: 0.08 * height },
  logoSpan: {
    alignSelf: "center",
    fontWeight: "600",
    fontSize: 0.03 * height,
    marginHorizontal: 20,
    color: "yellow",
  },
  imageContainer: {
    width: "90%",
    flex: 4,
    alignItems: "center",
    justifyContent: "center",
  },
  infoText: {
    fontWeight: "bold",
    fontSize: 0.03 * height,
    textAlign: "center",
    color: "white",
    marginVertical: 16,
    textTransform: "uppercase",
  },
  mainImage: {
    resizeMode: "contain",
    height: 0.35 * height,
    maxHeight: 400,
    minWidth: 0.7 * width,
    maxWidth: 300,
  },
  buttonContainer: {
    minWidth: 0.7 * width,
    marginVertical: 20,
    maxWidth: 300,
    justifyContent: "center",
    paddingVertical: 10,
    flex: 2,
  },
  button: {
    borderRadius: 25,
    margin: 7,
    paddingVertical: 0.013 * height,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "red",
    borderWidth: 2,
  },
  buttonActive: { backgroundColor: "red" },
  buttonTextActive: {
    fontWeight: "900",
    fontSize: 0.02 * height,
    color: "white",
  },
  buttonTextDisabled: {
    fontWeight: "900",
    fontSize: 0.02 * height,
    color: "red",
  },
});

export default styles;
